<template>
  <div>
    <Snackbar :SnackbarComponent="SnackbarComponent" />
    <div class="rootCard">
      <v-row justify="center" align="center" :style="{ height: `${TotalCardHeight}px` }">
        <v-col cols="12" align="center">
          <v-card width="1150" height="550" rounded="xl" elevation="1">
            <v-row>
              <v-col cols="12" md="6">
                <v-img cover src="@/assets/loginPageImg.jpg" />
              </v-col>
              <v-col cols="12" md="6">
                <v-window v-model="StepperValue">
                  <v-window-item :value="1">
                    <div class="d-flex align-center justify-center" :style="{ height: `${TotalCardHeight - 600}px` }">
                      <v-img max-width="250" src="@/assets/Quizarre-logo2.png" />
                    </div>
                    <div class="FontFamilyVariant1 FontSize50px font-weight-bold">Hello There,</div>
                    <div class="FontFamilyVariant1 FontSize20px font-weight-bold">Welcome To The Quizarre User Portal!</div>
                    <v-form ref="EmailValidateForm" class="mt-5">
                      <div class="text-left mx-5 font-weight-bold">Enter your Email</div>
                      <v-text-field
                        variant="outlined"
                        rounded="xl"
                        class="mx-3 text-left"
                        placeholder="john@google.com"
                        v-model="Login.EmailId"
                        :rules="[(v) => !!v || 'Required', (v) => /.+@.+/.test(v) || 'Email Must Be Valid']"
                      ></v-text-field>
                    </v-form>
                    <v-card-actions>
                      <v-btn class="text-capitalize mx-3" :loading="EmailLoader" variant="flat" color="primary" @click="ValidateMethod(1)">Verify Email</v-btn>
                    </v-card-actions>
                  </v-window-item>
                  <v-window-item :value="2">
                    <div class="d-flex align-center justify-center" :style="{ height: `${TotalCardHeight - 600}px` }">
                      <v-img max-width="250" src="@/assets/Quizarre-logo2.png" />
                    </div>
                    <div class="FontFamilyVariant1 FontSize50px font-weight-bold">Hello There,</div>
                    <div class="FontFamilyVariant1 FontSize20px font-weight-bold">Welcome To The Quizarre User Portal!</div>
                    <v-form ref="OTPValidateForm" class="mt-5">
                      <div class="text-left mx-8 font-weight-bold">You will receive an OTP in your email for verification.</div>
                      <v-otp-input length="6" class="OTPField text-left" v-model="Login.OTP" type="number" :rules="[(v) => !!v || 'required']"></v-otp-input>
                    </v-form>
                    <v-card-actions>
                      <v-btn class="text-capitalize mx-5" :loading="otpLoader" variant="flat" color="primary" @click="ValidateMethod(2)">Verify OTP</v-btn>
                    </v-card-actions>
                  </v-window-item>
                  <v-window-item :value="3">
                    <div class="d-flex align-center justify-center" :style="{ height: `${TotalCardHeight - 600}px` }">
                      <v-img max-width="250" src="@/assets/Quizarre-logo2.png" />
                    </div>
                    <div class="FontFamilyVariant1 FontSize50px font-weight-bold">Hello There,</div>
                    <div class="FontFamilyVariant1 FontSize20px font-weight-bold">Welcome To The Quizarre User Portal!</div>
                    <v-form ref="OrgCodeValidateForm" class="mt-5">
                      <div class="text-left mx-8 font-weight-bold">Enter your Organization Code</div>
                      <v-otp-input length="6" class="OTPField" v-model="Login.Org_Code"></v-otp-input>
                    </v-form>
                    <v-card-actions>
                      <v-btn class="text-capitalize mx-5" :loading="VerifyCodeLoader" variant="flat" color="primary" @click="ValidateMethod(3)">Verify Code</v-btn>
                    </v-card-actions>
                    <div class="FontSize14px text-center pt-4">
                      Don't have organization code? <span class="text-decoration-underline text-blue cursorPointer" @click="ValidateMethod(4)">Try Quizarre</span>
                    </div>
                  </v-window-item>
                </v-window>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { signIn, confirmSignIn, signOut } from "aws-amplify/auth";
import { GetCurrentUser } from "@/mixins/GetCurrentUser.js";
import { switchToATeam } from "@/graphql/mutations.js";
import { useDisplay } from "vuetify";
import { generateClient } from "aws-amplify/api";
const client = generateClient();

import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  components: {
    Snackbar,
  },
  mixins: [GetCurrentUser],
  data: () => ({
    TotalCardHeight: 0,
    StepperValue: 1,

    EmailLoader: false,
    otpLoader: false,
    VerifyCodeLoader: false,
    user: null,
    SnackbarComponent: {},
    Login: {
      EmailId: "",
      OTP: "",
      Org_Code: "",
    },
  }),
  watch: {
    "Login.EmailId"(val) {
      this.Login.EmailId = val.toLowerCase();
    },
  },
  mounted() {
    const display = useDisplay();
    this.TotalCardHeight = display.height._object.height;
  },
  methods: {
    async ValidateMethod(Step) {
      switch (Step) {
        case 1:
          try {
            const { valid } = await this.$refs.EmailValidateForm.validate();
            if (valid) {
              this.EmailLoader = true;
              this.user = await signIn({
                username: this.Login.EmailId,
                options: {
                  authFlowType: "CUSTOM_WITHOUT_SRP",
                },
              });
              if (this.user.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE") {
                this.SnackbarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "blueColorVariant2",
                  SnackbarText: `Verification OTP sent to ${this.Login.EmailId}`,
                };
                this.StepperValue = 2;
                this.EmailLoader = false;
              }
            } else {
              this.EmailLoader = false;
              this.SnackbarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "redColorVariant3",
                SnackbarText: "Please Enter Email ID to proceed.!",
              };
            }
          } catch (error) {
            if (error.message === "There is already a signed in user.") {
              await signOut({ global: true });
              this.ValidateMethod(1);
            } else {
              this.EmailLoader = false;
              this.SnackbarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "redColorVariant3",
                SnackbarText: error.message,
              };
            }
          }
          break;
        case 2:
          try {
            const { valid } = await this.$refs.OTPValidateForm.validate();
            console.log("Validd", valid);
            if (valid && this.Login.OTP !== "") {
              this.otpLoader = true;
              await confirmSignIn({ challengeResponse: this.Login.OTP })
                .then(() => {
                  this.SnackbarComponent = {
                    SnackbarVmodel: true,
                    SnackbarColor: "primary",
                    SnackbarText: "Login Successfull",
                  };
                  this.otpLoader = false;
                  sessionStorage.setItem("loggedin_user", this.Login.EmailId);
                  this.ActivateMethod();
                })
                .catch((err) => {
                  // if (err.message === "VerifyAuthChallengeResponse failed with error Incorrect OTP!!." || err.message === "Invalid session for the user.") {
                  //   this.counter = 0;
                  //   this.Login.otp = "";
                  // }
                  this.SnackbarComponent = {
                    SnackbarVmodel: true,
                    SnackbarColor: "redColorVariant3",
                    SnackbarText: err.message,
                  };
                  this.OTPErrorMessage = err.message;
                  this.otpLoader = false;
                });
            } else {
              this.SnackbarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "redColorVariant3",
                SnackbarText: "Please Enter OTP to proceed.!",
              };
            }
          } catch (error) {
            this.otpLoader = false;
            console.log("Error", error);
          }
          break;
        case 3:
          try {
            this.VerifyCodeLoader = true;
            let mutation_name = switchToATeam;
            let mutation_result = "switchToATeam";
            let result = await client.graphql({
              query: mutation_name,
              variables: {
                input: {
                  team_code: this.Login.Org_Code,
                  team_is_default: "FALSE",
                },
              },
            });
            let ResultObj = JSON.parse(result.data[mutation_result]);
            if (ResultObj.status == "SUCCESS") {
              this.SnackbarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "yellowColorVariant1",
                SnackbarText: ResultObj.status_message,
              };

              this.ActivateMethod();
            }
            this.VerifyCodeLoader = false;
          } catch (error) {
            this.VerifyCodeLoader = false;
            this.SnackbarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "redColorVariant3",
              SnackbarText: error.errors[0].message,
            };
            this.Login.team_code = "";
            console.log("Err", error);
          }
          break;
        case 4:
          try {
            let mutation_name = switchToATeam;
            let mutation_result = "switchToATeam";
            let result = await client.graphql({
              query: mutation_name,
              variables: {
                input: {
                  team_is_default: "TRUE",
                },
              },
            });
            let ResultObj = JSON.parse(result.data[mutation_result]);
            if (ResultObj.status == "SUCCESS") {
              this.SnackbarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "yellowColorVariant1",
                SnackbarText: ResultObj.status_message,
              };

              this.ActivateMethod();
            }
          } catch (error) {
            // this.SnackbarComponent = {
            //   SnackbarVmodel: true,
            //   SnackbarColor: "redColorVariant3",
            //   SnackbarText: error.errors[0].message,
            // };
            this.Login.team_code = "";
            console.log("Err", error);
          }
          break;
      }
    },
    async ActivateMethod() {
      let current_logged_in_user_result = await this.GetCurrentUserMethod();
      if (current_logged_in_user_result.status === "SUCCESS") {
        if (current_logged_in_user_result.is_team_switch_required) {
          this.StepperValue = 3;
        } else {
          this.$router.push("LandingPage");
        }
      }
    },
  },
};
</script>

<style>
.rootCard {
  background-image: url("@/assets/bg.png") !important;
  background-size: cover !important;
  height: 100vh !important;
}
</style>
