/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const switchToATeam = /* GraphQL */ `
  mutation SwitchToATeam($input: switchToATeamInput) {
    switchToATeam(input: $input)
  }
`;
export const createQuizAttempts = /* GraphQL */ `
  mutation CreateQuizAttempts($input: createQuizAttemptsInput) {
    createQuizAttempts(input: $input)
  }
`;
export const submitQuizQuestionAnswer = /* GraphQL */ `
  mutation SubmitQuizQuestionAnswer($input: submitQuizQuestionAnswerInput) {
    submitQuizQuestionAnswer(input: $input)
  }
`;
