import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/MainPages/LoginPage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/TakeQuizGame",
    name: "TakeQuizGame",
    component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/TakeQuizGame.vue"),
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    component: () => import(/* webpackChunkName: "about" */ "../views/MainPages/LandingPage.vue"),
    children: [
      {
        path: "/UpcomingGames",
        name: "UpcomingGames",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/UpcomingGames.vue"),
      },
      {
        path: "/LiveGames",
        name: "LiveGames",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/LiveGames.vue"),
      },
      {
        path: "/UpcomingGames",
        name: "UpcomingGames",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/UpcomingGames.vue"),
      },
      {
        path: "/CompletedGames",
        name: "CompletedGames",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/CompletedGames.vue"),
      },
      {
        path: "/Attempts",
        name: "Attempts",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/Attempts.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

